import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { CustomSwitchProps } from './types';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles(() =>
  createStyles({
    switchLabel: {
      fontSize: '14px',
      fontWeight: 'bold',
    },
  }),
);

function CustomSwitch(props: CustomSwitchProps) {
  const classes = useStyles();

  return (
    <FormControlLabel
      control={<Switch checked={props.toggle} onChange={props.handleToggle} name="user-type-toggle" color="primary" />}
      label={<Typography className={classes.switchLabel}>{props.label}</Typography>}
      labelPlacement={props.label.length > 0 ? 'start' : 'end'}
    />
  );
}

export default CustomSwitch;
