import * as React from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import { Device } from 'twilio-client';
import { useSelector } from 'react-redux';
import { useFirebase, useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import { filter, findIndex } from 'lodash';
import { colors } from '../../styles/variables';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Data, ModuleCompletion, Order, SMSMessages, FollowupMessages, UserProfile } from './types';
import { functions, RootState } from '../../store';
import EnhancedTableHead from './EnhancedTableHead';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import UserStatusChip from './UserStatusChip';
import CustomizedToggle from '../CustomizedToggle';
import CustomSwitch from './CustomSwitch';
import VerifyDialog from './Dialogs/VerifyDialog';
import SMSDialog from './Dialogs/SMSDialog';
import EmailFollowupDialog from './Dialogs/EmailFollowupDialog';
import UserDialog from './Dialogs/UserDialog';
import UserLogsCell from './Cells/UserLogsCell';
import ModuleProgressCell from './Cells/ModuleProgressCell';
import UserProfileCell from './Cells/UserProfileCell';
import StaticChipsArray from '../StaticChipsArray';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import Badge from '@material-ui/core/Badge';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EmailIcon from '@material-ui/icons/Email';
import CallIcon from '@material-ui/icons/Call';
import CallEndIcon from '@material-ui/icons/CallEnd';
import TimelineIcon from '@material-ui/icons/Timeline';
import RestoreIcon from '@material-ui/icons/Restore';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import FacebookIcon from '@material-ui/icons/Facebook';
import SmsIcon from '@material-ui/icons/Sms';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import { exportUserCSVFile, exportOtherUserCSVFile } from '../../utils/exportCSV';
import { formatPhone } from '../../utils/formatPhone';
import { formatDate } from '../../utils/timeFormatter';
const axios = require('axios').default;

function createData(
  id: string,
  email: string,
  flagged: boolean,
  phone: string,
  name: string,
  userType: string,
  pradasComplete: number,
  followupSurveyCompletion: number,
  moduleCompletion: ModuleCompletion[],
  status: string,
  emailFlag: string,
  smsFlag: string,
  smsNotification: boolean,
  createdAt: string,
  surveyCompletedAt: string,
  daysOfReg: number,
  profile: UserProfile,
): Data {
  return {
    id,
    email,
    flagged,
    phone,
    name,
    userType,
    pradasComplete,
    followupSurveyCompletion,
    moduleCompletion,
    status,
    emailFlag,
    smsFlag,
    smsNotification,
    createdAt,
    surveyCompletedAt,
    daysOfReg,
    profile,
  };
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string | boolean | ModuleCompletion[] | UserProfile },
  b: { [key in Key]: number | string | boolean | ModuleCompletion[] | UserProfile },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      borderRadius: '20px',
      marginBottom: theme.spacing(2),
    },
    filterPaper: {
      width: '100%',
      borderRadius: '20px',
      padding: theme.spacing(4),
      textAlign: 'center',
      marginBottom: theme.spacing(4),
    },
    table: {
      minWidth: 600,
    },
    tableRow: {
      fontSize: '12px',
      '&.Mui-selected:hover': {
        backgroundColor: '#f5f5f5',
      },
      '&.Mui-selected': {
        backgroundColor: 'white',
      },
    },
    tableCell: {
      fontSize: '12px',
    },
    iconCell: {
      minWidth: '300px',
      maxWidth: '300px',
      margin: 0,
      padding: 0,
      paddingRight: '24px !important',
    },
    expandCell: {
      minWidth: '200px',
      maxWidth: '200px',
      margin: 0,
      padding: 0,
      paddingRight: '24px !important',
    },
    iconButton: {
      marginLeft: '4px',
      '&:disabled': {
        color: colors.gray.disabled,
        backgroundColor: 'transparent',
        cursor: 'not-allowed',
        pointerEvents: 'all !important',
      },
    },
    facebookButton: {
      marginLeft: '4px',
      '&:disabled': {
        color: '#4267B2',
        backgroundColor: 'transparent',
        pointerEvents: 'all !important',
      },
    },
    caption: {
      padding: 8,
      fontSize: '14px',
    },
    toolbar: {
      '& > p:nth-of-type(2)': {
        fontSize: '14px',
        fontWeight: 500,
      },
    },
    menuItem: {
      fontSize: '14px',
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    alert: {
      fontSize: '14px',
    },
    switchLabel: {
      fontSize: '14px',
      fontWeight: 'bold',
    },
    badge: {
      color: 'white',
    },
  }),
);

const lastSeenToggles = ['ALL', 'ACTIVE', '> 7 DAYS', '> 14 DAYS', 'INACTIVE'];
const lastFollowupToggles = ['ALL', '> 7 DAYS', '> 14 DAYS', '> 21 DAYS', 'NEVER'];

const UserTable: React.FC = () => {
  const classes = useStyles();
  const firebase = useFirebase();
  const firestore = useFirestore();

  // Bind Firebase Metadata
  useFirestoreConnect(() => `config/meta`);
  const metaData = useSelector((state: RootState) => state.firestore.data.config && state.firestore.data.config.meta);
  // Bind SMS Messages
  useFirestoreConnect('sms');
  const sms = useSelector((state: RootState) => state.firestore.data.sms);
  // Bind Flagged Users
  useFirestoreConnect('flagged');
  const flagged = useSelector((state: RootState) => state.firestore.data.flagged);
  // Bind Followup Logs
  useFirestoreConnect('followup');
  const logs = useSelector((state: RootState) => state.firestore.data.followup);
  // Bind User Last Seen Data
  useFirestoreConnect('config/lastSeen');
  const lastSeen = useSelector(
    (state: RootState) => state.firestore.data.config && state.firestore.data.config.lastSeen,
  );
  // Bind User Membership
  useFirestoreConnect('membership');
  const membership = useSelector((state: RootState) => state.firestore.data.membership);
  // Bind User Groups
  useFirestoreConnect('groups');
  const groups = useSelector((state: RootState) => state.firestore.data.groups);

  const [rows, setRows] = React.useState<Data[]>([]);
  const [logOpen, setLogOpen] = React.useState<string[]>([]);
  const [moduleOpen, setModuleOpen] = React.useState<string[]>([]);
  const [profileOpen, setProfileOpen] = React.useState<string[]>([]);
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('pradasComplete');
  const [selected, setSelected] = React.useState<string[]>([]);
  const [searchStr, setSearchStr] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [emailOpen, setEmailOpen] = React.useState(false);
  const [emailRow, setEmailRow] = React.useState<Data | null>(null);
  const [smsOpen, setSmsOpen] = React.useState(false);
  const [smsRow, setSmsRow] = React.useState<Data | null>(null);
  const [followupOpen, setFollowupOpen] = React.useState(false);
  const [followupRow, setFollowupRow] = React.useState<Data | null>(null);
  const [verifyOpen, setVerifyOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [emailLoading, setEmailLoading] = React.useState(false);
  const [smsLoading, setSmsLoading] = React.useState(false);
  const [followupLoading, setFollowupLoading] = React.useState(false);
  const [updateEmailLoading, setUpdateEmailLoading] = React.useState(false);
  const [updatePhoneLoading, setUpdatePhoneLoading] = React.useState(false);
  const [verifyLoading, setVerifyLoading] = React.useState(false);
  const [alert, setAlert] = React.useState('');
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState<'success' | 'error' | 'warning' | 'info'>('success');
  const [callSid, setCallSid] = React.useState('');
  const [connected, setConnected] = React.useState(false);
  const [onPhone, setOnPhone] = React.useState<string | null>(null);
  const [logLoading, setLogLoading] = React.useState(false);
  const [lastSeenToggle, setLastSeenToggle] = React.useState('ALL');
  const [lastFollowupToggle, setLastFollowupToggle] = React.useState('ALL');
  const [userTypeToggle, setUserTypeToggle] = React.useState(false);
  const [attentionToggle, setAttentionToggle] = React.useState(false);
  const [followupToggle, setFollowupToggle] = React.useState(false);
  const [showOptoutToggle, setShowOptoutToggle] = React.useState(false);
  const [showStopFollowupToggle, setShowStopFollowupToggle] = React.useState(false);
  const [showContactCompletedToggle, setShowContactCompletedToggle] = React.useState(false);
  const [manualLogs, setManualLogs] = React.useState<string[]>([]);

  React.useEffect(() => {
    async function initialize() {
      const programData = (await firestore.collection('users').get()).docs;

      const tmpRows: Data[] = [];
      const currentDate = new Date().getTime();

      programData.forEach(user => {
        const data = user.data() as UserProfile;
        if (data) {
          const {
            email,
            flagged,
            userPhone,
            userFirstname,
            userSurname,
            userCategory,
            surveyCompletion,
            followupSurveyCompletion,
            moduleCompletion,
            status,
            optOutAt,
            emailFlag,
            smsFlag,
            smsNotification,
            createdAt,
            surveyCompletedAt,
          } = data;

          if (
            email &&
            userPhone &&
            userFirstname &&
            userSurname &&
            userCategory &&
            surveyCompletion !== null &&
            moduleCompletion &&
            status &&
            createdAt
          ) {
            const daysOfReg = (currentDate - new Date(formatDate(createdAt)).getTime()) / 1000 / 60 / 60 / 24;

            tmpRows.push(
              createData(
                user.id,
                email,
                flagged ? true : false,
                userPhone,
                `${userFirstname} ${userSurname}`,
                userCategory,
                surveyCompletion,
                followupSurveyCompletion ? followupSurveyCompletion : 0,
                moduleCompletion,
                optOutAt ? 'opt-out' : status,
                emailFlag ? emailFlag : '',
                smsFlag ? smsFlag : '',
                smsNotification,
                formatDate(createdAt),
                surveyCompletedAt ? formatDate(surveyCompletedAt) : '',
                daysOfReg,
                data,
              ),
            );
          }
        }
      });

      setRows(tmpRows);
      const token = await firebase.auth().currentUser?.getIdToken();
      setLoading(false);

      Device.destroy();

      // Fetch Twilio capability token from our Node.js server
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

      axios
        .get(`${process.env.GATSBY_TWILIO_ADDRESS}/token`, {
          crossdomain: true,
        })
        .then(function(data: any) {
          Device.setup(data.data.token);
          setConnected(true);
        })
        .catch(function(err: any) {
          setAlert(`Failed to fetch Twilio token: ${err}`);
          setAlertOpen(true);
          setSeverity('error');
        });

      // Configure event handlers for Twilio Device
      Device.on('disconnect', function() {
        setOnPhone(null);
      });

      Device.on('connect', function(connection: any) {
        // console.log(connection.parameters.CallSid);
        setCallSid(connection.parameters.CallSid);
      });
    }

    setLoading(true);
    initialize();
  }, []);

  // Set the id of rows currently open for followup logs (and remove from module open)
  const setLogRowOpen = (id: string) => {
    const idx = logOpen.indexOf(id);
    const tmpOpen = [...logOpen];
    if (idx >= 0) tmpOpen.splice(idx, 1);
    else tmpOpen.push(id);

    setLogOpen(tmpOpen);
    removeFromModuleOpen(id);
    removeFromProfileOpen(id);
  };

  // Set the id of rows currently open for module logs (and remove from log open)
  const setModuleRowOpen = (id: string) => {
    const idx = moduleOpen.indexOf(id);
    const tmpOpen = [...moduleOpen];
    if (idx >= 0) tmpOpen.splice(idx, 1);
    else tmpOpen.push(id);

    setModuleOpen(tmpOpen);
    removeFromLogOpen(id);
    removeFromProfileOpen(id);
  };

  // Set the id of rows currently open for user profile (and remove from log & module open)
  const setProfileRowOpen = (id: string) => {
    const idx = profileOpen.indexOf(id);
    const tmpOpen = [...profileOpen];
    if (idx >= 0) tmpOpen.splice(idx, 1);
    else tmpOpen.push(id);

    setProfileOpen(tmpOpen);
    removeFromLogOpen(id);
    removeFromModuleOpen(id);
  };

  const removeFromLogOpen = (id: string) => {
    const logIdx = logOpen.indexOf(id);
    const tempLogOpen = [...logOpen];
    if (logIdx >= 0) {
      tempLogOpen.splice(logIdx, 1);
      setLogOpen(tempLogOpen);
    }
  };

  const removeFromModuleOpen = (id: string) => {
    const moduleIdx = moduleOpen.indexOf(id);
    const tempModuleOpen = [...moduleOpen];
    if (moduleIdx >= 0) {
      tempModuleOpen.splice(moduleIdx, 1);
      setModuleOpen(tempModuleOpen);
    }
  };

  const removeFromProfileOpen = (id: string) => {
    const profileIdx = profileOpen.indexOf(id);
    const tempProfileOpen = [...profileOpen];
    if (profileIdx >= 0) {
      tempProfileOpen.splice(profileIdx, 1);
      setProfileOpen(tempProfileOpen);
    }
  };

  const handleRequestSort = (property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const updateSearchStr = (str: string) => {
    setPage(0);
    setSearchStr(str);
  };

  const download = () => {
    exportUserCSVFile(rows, logs, lastSeen, membership, metaData, groups);
  };

  const downloadOther = () => {
    exportOtherUserCSVFile(lastSeen);
  };

  const onClickVerify = () => {
    setVerifyOpen(true);
  };

  const isPhoneInUse = (id: string) => {
    if (metaData) return metaData.phoneInUse && onPhone !== null && onPhone !== id;
    return true;
  };

  const handleEmailClose = () => {
    setEmailOpen(false);
  };

  // Handle email sending by calling Firebase HTTP Callable Function
  const handleEmailSend = async (str: string) => {
    setEmailLoading(true);

    if (functions.sendNotificationFn && emailRow) {
      const queryObj = {
        uid: emailRow.id,
        to: emailRow.email,
        content: str,
        firstName: emailRow.name,
        email: firebase.auth().currentUser?.email,
      };

      const result: any = await functions.sendNotificationFn(queryObj);
      setAlertOpen(true);
      setAlert(result.data.message);
      if (result.data.status === 200) setSeverity('success');
      else setSeverity('error');
    }
    setEmailOpen(false);
    setEmailLoading(false);
  };

  const handleSmsClose = () => {
    setSmsOpen(false);
  };

  // Handle SMS Sending by Calling Firebase HTTP Callable Function
  const handleSmsSend = async (str: string) => {
    setSmsLoading(true);

    if (functions.sendNotificationFn && smsRow) {
      const queryObj = {
        uid: smsRow.id,
        to: smsRow.email,
        content: str,
        firstName: smsRow.name,
        phone: formatPhone(smsRow.phone),
        email: firebase.auth().currentUser?.email,
      };

      const result: any = await functions.sendNotificationFn(queryObj);
      setAlertOpen(true);
      setAlert(result.data.message);
      if (result.data.status === 200) setSeverity('success');
      else setSeverity('error');
    }

    setSmsLoading(false);
  };

  // Handle SMS Resolve by Calling Firebase HTTP Callable Function
  const handleSmsResolved = async () => {
    setSmsLoading(true);

    if (functions.resolveSmsFn && smsRow) {
      const queryObj = {
        uid: smsRow.id,
        email: firebase.auth().currentUser?.email,
      };

      const result: any = await functions.resolveSmsFn(queryObj);
      setAlertOpen(true);
      setAlert(result.data.message);
      if (result.data.status === 200) setSeverity('success');
      else setSeverity('error');
    }

    setSmsOpen(false);
    setSmsLoading(false);
  };

  const handleFollowupClose = () => {
    setFollowupOpen(false);
    setFollowupRow(null);
  };

  const handleVerifyClose = () => {
    setVerifyOpen(false);
  };

  // onClick event for the Email follow-up action to open the Email dialog
  const onClickEmail = (row: Data) => {
    setEmailOpen(true);
    setEmailRow(row);
  };

  // onClick event for the SMS follow-up action to open the SMS dialog
  const onClickSMS = (row: Data) => {
    setSmsOpen(true);
    setSmsRow(row);
  };

  // onClick event for the Manual Logs follow-up action to create new log entry
  // for the current user
  const onClickManualLogs = async (row: Data) => {
    const openIdx = logOpen.indexOf(row.id);
    const tmpOpen = [...logOpen];
    if (openIdx < 0) tmpOpen.push(row.id);

    setLogOpen(tmpOpen);

    const idx = manualLogs.indexOf(row.id);
    const tmpManualLogs = [...manualLogs];
    if (idx < 0) tmpManualLogs.push(row.id);

    setManualLogs(tmpManualLogs);
  };

  const onClickFollowup = (row: Data) => {
    setFollowupRow(row);
    setFollowupOpen(true);
  };

  const onClickPhone = async (row: Data) => {
    if (!onPhone) {
      Device.connect({
        code: 'caller',
        callSid: callSid,
        number: `${process.env.GATSBY_TWILIO_NUMBER}`,
      });

      const idToken = await firebase.auth().currentUser?.getIdToken();
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + idToken;
      axios
        .post(`${process.env.GATSBY_TWILIO_ADDRESS}/conference`, {
          code: 'add',
          number: formatPhone(row.phone),
          crossdomain: true,
        })
        .then(async () => {
          setOnPhone(row.id);

          const queryObj = {
            uid: row.id,
            email: firebase.auth().currentUser?.email,
            status: 'running',
          };

          const result: any = await functions.updateCallLogsFn(queryObj);
          if (result.data.status !== 200) {
            setAlertOpen(true);
            setAlert(result.data.message);
            setSeverity('error');
          } else {
            if (logOpen.indexOf(row.id) < 0) setLogRowOpen(row.id);
          }
        })
        .catch((err: any) => {
          setAlert(`Failed to call the parent: ${err}`);
          setAlertOpen(true);
          setSeverity('error');
        });
    } else {
      Device.disconnectAll();

      const queryObj = {
        uid: row.id,
        email: firebase.auth().currentUser?.email,
        status: 'ended',
      };

      const result: any = await functions.updateCallLogsFn(queryObj);
      if (result.data.status !== 200) {
        setAlertOpen(true);
        setAlert(result.data.message);
        setSeverity('error');
      }
    }
  };

  const handleClose = () => {
    setAlertOpen(false);
  };

  const handleNoteSubmit = async (str: string, uid: string, startTime: any) => {
    setLogLoading(true);
    console.log('str: ', str);
    console.log('uid: ', uid);

    // Fetch all the followup logs from Firestore
    const followupData = (
      await firestore
        .collection('followup')
        .doc(uid)
        .get()
    ).data();

    if (followupData && followupData.logs) {
      // Find the call log to be updated
      const idx = findIndex(followupData.logs, (log: FollowupMessages) => {
        return log.startTime.seconds === startTime.seconds;
      });

      // If log exists, update the follow-up log notes
      if (idx >= 0) {
        const tmpMessages = [...followupData.logs];
        tmpMessages[idx].notes = str;
        tmpMessages[idx].status = 'done';
        await firestore
          .collection('followup')
          .doc(uid)
          .update({ logs: tmpMessages });
      }
    }

    setLogLoading(false);
  };

  const deleteManualLog = (uid: string, logs: FollowupMessages[] | undefined) => {
    if (!logs) setLogRowOpen(uid);

    const idx = manualLogs.indexOf(uid);
    const tmpManualLogs = [...manualLogs];
    if (idx >= 0) tmpManualLogs.splice(idx, 1);

    setManualLogs(tmpManualLogs);
  };

  const saveManualLog = async (uid: string, content: string) => {
    setLogLoading(true);

    // Save the manual log to database via the `saveManualLogFn` firebase function
    if (functions.saveManualLogFn) {
      const queryObj = {
        uid: uid,
        content: content,
        email: firebase.auth().currentUser?.email,
      };

      const result: any = await functions.saveManualLogFn(queryObj);
      setAlertOpen(true);
      setAlert(result.data.message);
      if (result.data.status === 200) setSeverity('success');
      else setSeverity('error');
    }

    setLogLoading(false);

    // Remove manual logs for the current user
    const idx = manualLogs.indexOf(uid);
    const tmpManualLogs = [...manualLogs];
    if (idx >= 0) tmpManualLogs.splice(idx, 1);

    setManualLogs(tmpManualLogs);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const getBadgeContent = (id: string) => {
    if (sms && Object.keys(sms) && Object.keys(sms).indexOf(id) >= 0)
      return filter(sms[id].messages, (message: SMSMessages) => message.from !== 'admin' && !message.replied).length;
    return 0;
  };

  const getRowMessages = () => {
    if (sms && Object.keys(sms) && smsRow && Object.keys(sms).indexOf(smsRow.id) >= 0)
      return sms[smsRow.id].messages as SMSMessages[];
  };

  const hasFollowupLogs = (id: string) => {
    if (logs && Object.keys(logs) && Object.keys(logs).indexOf(id) >= 0) return true;
    return false;
  };

  const lastFollowupLog = (id: string) => {
    const currentTimestamp = new Date().getTime();
    if (logs && Object.keys(logs) && Object.keys(logs).indexOf(id) >= 0) {
      const logsArr = logs[id].logs;
      const lastLog: FollowupMessages = logsArr[logsArr.length - 1];
      const endTime = lastLog.endTime ? lastLog.endTime.seconds : lastLog.startTime.seconds;
      return (currentTimestamp / 1000 - endTime) / 60 / 60;
    }
    return null;
  };

  const getRowLogs = (id: string) => {
    if (logs && Object.keys(logs) && Object.keys(logs).indexOf(id) >= 0)
      return [...logs[id].logs].reverse() as FollowupMessages[];
  };

  const handleLastSeenChange = (lastSeen: string) => {
    setPage(0);
    setLastSeenToggle(lastSeen);
  };

  const handleLastFollowupChange = (lastFollowup: string) => {
    setPage(0);
    setLastFollowupToggle(lastFollowup);
  };

  const getFollowupTarget = () => {
    switch (lastFollowupToggle) {
      case '> 7 DAYS':
        return 24 * 7;
      case '> 14 DAYS':
        return 24 * 14;
      case '> 21 DAYS':
        return 24 * 21;
      case 'NEVER':
        return null;
      default:
        return null;
    }
  };

  const handleUserTypeToggle = () => {
    setPage(0);
    setUserTypeToggle(!userTypeToggle);
  };

  const handleAttentionToggle = () => {
    setPage(0);
    setAttentionToggle(!attentionToggle);
  };

  const handleShowOptoutToggle = () => {
    setPage(0);
    setShowOptoutToggle(!showOptoutToggle);
  };

  const handleShowStopFollowupToggle = () => {
    setPage(0);
    setShowStopFollowupToggle(!showStopFollowupToggle);
  };

  const handleShowContactCompletedToggle = () => {
    setPage(0);
    setShowContactCompletedToggle(!showContactCompletedToggle);
  };

  const handleFollowupTogger = () => {
    setPage(0);
    setFollowupToggle(!followupToggle);
  };

  // Get user override status from `/config/meta/stopFollowupUsers` && `/config/meta/contactCompletedUsers`
  const getOverrideStatus = (row: Data) => {
    if (metaData && metaData.stopFollowupUsers && metaData.contactCompletedUsers) {
      if (row.status === 'opt-out') return 'opt-out';

      if (metaData.contactCompletedUsers.indexOf(row.id) >= 0) return 'contact completed';

      if (row.profile.surveyCompletedAt) {
        const currentDate = new Date().getTime();
        const diffDay =
          (currentDate - new Date(formatDate(row.profile.surveyCompletedAt)).getTime()) / 1000 / 60 / 60 / 24;
        if (diffDay >= 90) return '90-day Survey';
      }

      if (metaData.stopFollowupUsers.indexOf(row.id) >= 0) return 'stop followup';
    }

    return '';
  };

  // Get number of account warning for the current user (with SMS/Email Flag)
  const getAccountWarning = (row: Data) => {
    let count = 0;
    if (row.smsFlag.length > 0) count++;
    if (row.emailFlag.length > 0) count++;
    return count;
  };

  // Opt out the user
  const optOutUser = async (optOutNote: string) => {
    if (optOutNote.length === 0) {
      setAlertOpen(true);
      setAlert('Please provide a reason for opting out actions.');
      setSeverity('warning');
    } else {
      if (followupRow) {
        setFollowupLoading(true);

        // Opt the user out via the `optOutUserFn` firebase function
        const queryObj = {
          uid: followupRow.id,
          content: optOutNote,
          email: firebase.auth().currentUser?.email,
        };

        const result: any = await functions.optOutUserFn(queryObj);
        setAlert(result.data.message);
        if (result.data.status === 200) setSeverity('success');
        else setSeverity('error');
        setAlertOpen(true);

        const idx = findIndex(rows, row => row.id === followupRow.id);
        let tmpRow = rows[idx];
        tmpRow.status = 'opt-out';
        const tmpRows = [...rows];
        tmpRows[idx] = tmpRow;
        setRows(tmpRows);

        setFollowupLoading(false);
      }
    }
  };

  // Add user to the stop followup user list
  const addStopFollowupUser = async (followupNote: string) => {
    if (followupNote.length === 0) {
      setAlertOpen(true);
      setAlert('Please provide a reason for stopping follow-up actions.');
      setSeverity('warning');
    } else {
      if (followupRow) {
        setFollowupLoading(true);

        // Save the manual log to database via the `saveManualLogFn` firebase function
        if (functions.saveManualLogFn) {
          const queryObj = {
            uid: followupRow.id,
            content: followupNote,
            email: firebase.auth().currentUser?.email,
          };

          const result: any = await functions.saveManualLogFn(queryObj);
          setAlert(result.data.message);
          if (result.data.status === 200) setSeverity('success');
          else setSeverity('error');
          setAlertOpen(true);
        }

        await firestore.doc('config/meta').update({
          stopFollowupUsers: firestore.FieldValue.arrayUnion(followupRow.id),
        });

        setFollowupLoading(false);
      }
    }
  };

  // Add user to the contact completed user list
  const addContactCompletedUser = async (contactNote: string) => {
    if (contactNote.length === 0) {
      setAlertOpen(true);
      setAlert('Please provide a reason for contact completion actions.');
      setSeverity('warning');
    } else {
      if (followupRow) {
        setFollowupLoading(true);

        // Save the manual log to database via the `saveManualLogFn` firebase function
        if (functions.saveManualLogFn) {
          const queryObj = {
            uid: followupRow.id,
            content: contactNote,
            email: firebase.auth().currentUser?.email,
          };

          const result: any = await functions.saveManualLogFn(queryObj);
          setAlert(result.data.message);
          if (result.data.status === 200) setSeverity('success');
          else setSeverity('error');
          setAlertOpen(true);
        }

        await firestore.doc('config/meta').update({
          contactCompletedUsers: firestore.FieldValue.arrayUnion(followupRow.id),
        });

        setFollowupLoading(false);
      }
    }
  };

  const updateSMSNotification = async (value: boolean) => {
    if (followupRow) {
      setFollowupLoading(true);
      await firestore
        .collection('users')
        .doc(followupRow.id)
        .update({ smsNotification: value });
      setFollowupLoading(false);
    }
  };

  // Update parent email or phone number from the dashboard if needed
  const updateUserDetail = async (type: string, value: string) => {
    if (value.length === 0) {
      setAlertOpen(true);
      setAlert(`Please provide a valid ${type} for the update`);
      setSeverity('warning');
    } else {
      if (followupRow) {
        if (type === 'email') setUpdateEmailLoading(true);
        if (type === 'phone') setUpdatePhoneLoading(true);

        // Update user details via the `updateUserDetailFn` firebase function
        if (functions.updateUserDetailFn) {
          const queryObj = {
            parentId: followupRow.id,
            adminEmail: firebase.auth().currentUser?.email,
            type: type,
            value: value,
          };

          const result: any = await functions.updateUserDetailFn(queryObj);
          setAlert(result.data.message);
          if (result.data.status === 200) setSeverity('success');
          else setSeverity('error');
          setAlertOpen(true);

          // Update local user data according
          const idx = findIndex(rows, (row: Data) => {
            return row.id === followupRow.id;
          });

          let tmpRows = [...rows];
          if (type === 'email') {
            tmpRows[idx].email = value;
            tmpRows[idx].emailFlag = '';
            setRows(tmpRows);
            setUpdateEmailLoading(false);
          }

          if (type === 'phone') {
            tmpRows[idx].phone = value;
            tmpRows[idx].smsFlag = '';
            setRows(tmpRows);
            setUpdatePhoneLoading(false);
          }
        }
      }
    }
  };

  /**
   * Check whether the group membership for the current user has been recorded or not, if not,
   * add the record into the database via the `recordMembershipFn` cloud function
   *
   * @param email Participant registered PiP+ email
   * @param code Verification code
   * @param name Facebook user name (for matching the PiP+ and Facebook account)
   * @param group Applied Facebook PSG ID
   *
   * @returns Return string (Firebase Function Error) | undefined (successful)
   */
  const recordPSGMembership = async (email: string, code: string, name: string, group: string) => {
    setVerifyLoading(true);

    // Save the manual log to database via the `saveManualLogFn` firebase function
    const queryObj = {
      email: email,
      code: code,
      name: name,
      group: group,
      adminEmail: firebase.auth().currentUser?.email,
    };

    const result: any = await functions.recordMembershipFn(queryObj);
    setVerifyLoading(false);

    return result.data.message as string;
  };

  /**
   * Verify the membership request by checking whether the provided email account
   * exists in the database and whether the token matches
   *
   * @param email Registered PiP+ email account
   * @param token PSG token for verification
   *
   * @returns 0: Invalid Email, 1: Verification Successful, 2: Invalid Token
   */
  const verifyPSGToken = async (email: string, token: string) => {
    const user = await firestore
      .collection('users')
      .where('userContactEmail', '==', email)
      .get();

    if (user.size === 0) return 0;
    else {
      const uid = user.docs[0].id;
      return uid.substring(uid.length - 6) === token ? 1 : 2;
    }
  };

  const getPSGs = (uid: string) => {
    if (membership && groups && Object.keys(membership) && Object.keys(membership).indexOf(uid) >= 0) {
      const psgs = [];
      for (let i = 0; i < membership[uid].groups.length; i++) {
        const group = membership[uid].groups[i].group;
        psgs.push(groups[group].name);
      }
      return psgs;
    }

    return [];
  };

  // Check whether the user with a given uid is flagged by PiP+ admin
  const userFlagged = (uid: string) => {
    if (flagged && Object.keys(flagged) && Object.keys(flagged).indexOf(uid) >= 0) return true;
    return false;
  };

  // Handle user flag toggle click to flag/unflag users
  const handleUserFlag = async (uid: string) => {
    const flagRef = firestore.doc(`flagged/${uid}`);
    const flagDoc = await flagRef.get();
    if (flagDoc.exists) await flagRef.delete();
    else flagRef.set({ admin: firebase.auth().currentUser?.email });
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const filterRow = (row: Data) => {
    let lastSeen = true;
    let lastFollowup = true;
    let userType = true;
    let needsAttention = true;
    let showOptout = true;
    let showStopFollowup = true;
    let showContactCompleted = true;
    let showNonFollowup = true;

    // Last Seen Filter
    if (lastSeenToggle !== 'ALL')
      lastSeen = row.status.toUpperCase() === lastSeenToggle && getOverrideStatus(row) !== '90-day Survey';

    // Last Follow-up Filter
    if (lastFollowupToggle !== 'ALL') {
      const target = getFollowupTarget();
      const followupTime = lastFollowupLog(row.id);
      if (!target) lastFollowup = followupTime === null;
      else lastFollowup = followupTime !== null && followupTime >= target;
    }

    // User Type Filter
    if (!userTypeToggle && row.userType !== 'parent') userType = false;

    // Attention Filter
    if (attentionToggle && getBadgeContent(row.id) === 0 && !row.emailFlag && !row.smsFlag && !userFlagged(row.id))
      needsAttention = false;

    // Followup Survey Filter (show only the user who has created profile for more than 111 days)
    if (followupToggle) {
      if (!row.profile.surveyCompletedAt) showNonFollowup = false;
      else {
        const currentDate = new Date().getTime();
        const diffDay = (currentDate - new Date(row.surveyCompletedAt).getTime()) / 1000 / 60 / 60 / 24;
        if (diffDay < 111) showNonFollowup = false;
      }
    }

    const overrideStatus = getOverrideStatus(row);
    // Show Opt Out Filter
    if (!showOptoutToggle && overrideStatus === 'opt-out') showOptout = false;
    // Show Stop Followup Filter
    if (!showStopFollowupToggle && overrideStatus === 'stop followup') showStopFollowup = false;
    // Show Stop Followup Filter
    if (!showContactCompletedToggle && overrideStatus === 'contact completed') showContactCompleted = false;

    return (
      Object.values(row)
        .join('')
        .toLowerCase()
        .indexOf(searchStr.toLowerCase()) >= 0 &&
      lastSeen &&
      lastFollowup &&
      userType &&
      needsAttention &&
      showNonFollowup &&
      showOptout &&
      showStopFollowup &&
      showContactCompleted
    );
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.filterPaper}>
        <Grid container spacing={2} justify="center">
          <Grid item xs={12} md={4} container justify="center">
            <CustomSwitch toggle={userTypeToggle} handleToggle={handleUserTypeToggle} label="Show Other Users" />
          </Grid>
          <Grid item xs={12} md={4} container justify="center">
            <CustomSwitch
              toggle={attentionToggle}
              handleToggle={handleAttentionToggle}
              label="Show Users Need Attention"
            />
          </Grid>
          <Grid item xs={12} md={4} container justify="center">
            <CustomSwitch
              toggle={followupToggle}
              handleToggle={handleFollowupTogger}
              label="Show Users at 90 + 21 Days"
            />
          </Grid>
          <Grid item xs={12} md={4} container justify="center">
            <CustomSwitch toggle={showOptoutToggle} handleToggle={handleShowOptoutToggle} label="Show Opt-out" />
          </Grid>
          <Grid item xs={12} md={4} container justify="center">
            <CustomSwitch
              toggle={showStopFollowupToggle}
              handleToggle={handleShowStopFollowupToggle}
              label="Show Stop Followup"
            />
          </Grid>
          <Grid item xs={12} md={4} container justify="center">
            <CustomSwitch
              toggle={showContactCompletedToggle}
              handleToggle={handleShowContactCompletedToggle}
              label="Show Contact Completed"
            />
          </Grid>

          <Grid item xs={10} container spacing={1}>
            <Grid item xs={12} md={6} container justify="center" alignContent="center">
              <Typography className={classes.switchLabel}>Filter by Last Seen Time</Typography>
            </Grid>
            <Grid item xs={12} md={6} container justify="center" alignContent="center">
              <CustomizedToggle
                toggleLabel={lastSeenToggles}
                toggleValue={lastSeenToggle}
                handleToggleChange={handleLastSeenChange}></CustomizedToggle>
            </Grid>
          </Grid>

          <Grid item xs={10} container spacing={1}>
            <Grid item xs={12} md={6} container justify="center" alignContent="center">
              <Typography className={classes.switchLabel}>Filter by Last Follow-up Time</Typography>
            </Grid>
            <Grid item xs={12} md={6} container justify="center" alignContent="center">
              <CustomizedToggle
                toggleLabel={lastFollowupToggles}
                toggleValue={lastFollowupToggle}
                handleToggleChange={handleLastFollowupChange}></CustomizedToggle>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      <EmailFollowupDialog
        open={emailOpen}
        row={emailRow}
        templates={metaData && metaData.emails}
        emailLoading={emailLoading}
        handleEmailClose={handleEmailClose}
        handleEmailSend={handleEmailSend}
      />

      <SMSDialog
        open={smsOpen}
        row={smsRow}
        needResolve={smsRow ? getBadgeContent(smsRow.id) : 0}
        messages={getRowMessages()}
        smsLoading={smsLoading}
        handleSmsClose={handleSmsClose}
        handleSmsSend={handleSmsSend}
        handleSmsResolved={handleSmsResolved}
      />

      <UserDialog
        open={followupOpen}
        row={followupRow}
        followupLoading={followupLoading}
        emailLoading={updateEmailLoading}
        phoneLoading={updatePhoneLoading}
        userStatus={followupRow ? getOverrideStatus(followupRow) : ''}
        handleFollowupClose={handleFollowupClose}
        updateUserDetail={updateUserDetail}
        optOutUser={optOutUser}
        addStopFollowupUser={addStopFollowupUser}
        addContactCompletedUser={addContactCompletedUser}
        updateSMSNotification={updateSMSNotification}
      />

      <VerifyDialog
        open={verifyOpen}
        verifyLoading={verifyLoading}
        handleVerifyClose={handleVerifyClose}
        recordPSGMembership={recordPSGMembership}
        verifyPSGToken={verifyPSGToken}
      />

      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          updateSearchStr={updateSearchStr}
          download={download}
          downloadOther={downloadOther}
          onClickVerify={onClickVerify}
          lastSeen={lastSeen}
        />
        <TableContainer>
          {loading && <CircularProgress size={48} className={classes.buttonProgress} />}
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table">
            <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />

            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .filter(row => {
                  return filterRow(row);
                })
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <React.Fragment key={row.id}>
                      <TableRow
                        hover
                        onClick={() => handleClick(row.name)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                        className={classes.tableRow}>
                        <TableCell className={classes.expandCell}>
                          <Grid container spacing={0}>
                            <Grid item xs={3}>
                              <Tooltip
                                title={
                                  !row.moduleCompletion || row.moduleCompletion.length === 0
                                    ? ''
                                    : 'View Module Progress'
                                }>
                                <span>
                                  <IconButton
                                    className={classes.iconButton}
                                    color="primary"
                                    disabled={!row.moduleCompletion || row.moduleCompletion.length === 0}
                                    aria-label="expand-module-row"
                                    onClick={() => setModuleRowOpen(row.id)}>
                                    {moduleOpen.indexOf(row.id) >= 0 ? <KeyboardArrowUpIcon /> : <TimelineIcon />}
                                  </IconButton>
                                </span>
                              </Tooltip>
                            </Grid>

                            <Grid item xs={3}>
                              <Tooltip title={!hasFollowupLogs(row.id) ? '' : 'View Follow-up Logs'}>
                                <span>
                                  <IconButton
                                    className={classes.iconButton}
                                    color="primary"
                                    disabled={!hasFollowupLogs(row.id)}
                                    aria-label="expand-logs-row"
                                    onClick={() => setLogRowOpen(row.id)}>
                                    {logOpen.indexOf(row.id) >= 0 ? <KeyboardArrowUpIcon /> : <RestoreIcon />}
                                  </IconButton>
                                </span>
                              </Tooltip>
                            </Grid>

                            <Grid item xs={3}>
                              <Tooltip title="User Profile">
                                <span>
                                  <IconButton
                                    className={classes.iconButton}
                                    color="primary"
                                    aria-label="expand-profile-row"
                                    onClick={() => setProfileRowOpen(row.id)}>
                                    {profileOpen.indexOf(row.id) >= 0 ? <KeyboardArrowUpIcon /> : <AccountBoxIcon />}
                                  </IconButton>
                                </span>
                              </Tooltip>
                            </Grid>

                            {row.profile.isFacebookUser && (
                              <Grid item xs={3}>
                                <IconButton className={classes.facebookButton} disabled aria-label="facebook-user">
                                  <FacebookIcon />
                                </IconButton>
                              </Grid>
                            )}
                          </Grid>
                        </TableCell>
                        <TableCell className={classes.tableCell} component="th" id={labelId} scope="row" padding="none">
                          {row.id}
                        </TableCell>
                        <TableCell className={classes.tableCell}>{row.email}</TableCell>
                        <TableCell className={classes.tableCell}>
                          <CustomSwitch
                            toggle={userFlagged(row.id)}
                            handleToggle={() => handleUserFlag(row.id)}
                            label=""
                          />
                        </TableCell>
                        <TableCell className={classes.tableCell}>{row.phone}</TableCell>
                        <TableCell className={classes.tableCell}>{row.name}</TableCell>
                        <TableCell className={classes.tableCell}>{row.createdAt}</TableCell>
                        <TableCell className={classes.tableCell}>{row.userType.toUpperCase()}</TableCell>
                        <TableCell className={classes.tableCell}>
                          <PieChart
                            data={[{ value: Math.round(row.pradasComplete * 100), color: colors.brand }]}
                            style={{ height: '50px', width: '50px' }}
                            totalValue={100}
                            background="#ebebe4"
                            lengthAngle={360}
                            label={() => Math.round(row.pradasComplete * 100) + '%'}
                            labelStyle={{
                              fontSize: '24px',
                              fontWeight: 'bold',
                            }}
                            labelPosition={0}
                            lineWidth={20}
                          />
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <PieChart
                            data={[{ value: Math.round(row.followupSurveyCompletion * 100), color: colors.brand }]}
                            style={{ height: '50px', width: '50px' }}
                            totalValue={100}
                            background="#ebebe4"
                            lengthAngle={360}
                            label={() => Math.round(row.followupSurveyCompletion * 100) + '%'}
                            labelStyle={{
                              fontSize: '24px',
                              fontWeight: 'bold',
                            }}
                            labelPosition={0}
                            lineWidth={20}
                          />
                        </TableCell>
                        <TableCell className={classes.tableCell}>{row.surveyCompletedAt}</TableCell>
                        <TableCell className={classes.tableCell}>
                          <UserStatusChip
                            status={
                              getOverrideStatus(row) ? getOverrideStatus(row) : row.status ? row.status : 'inactive'
                            }
                          />
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          <StaticChipsArray data={getPSGs(row.id)} />
                        </TableCell>
                        <TableCell className={classes.iconCell}>
                          <Grid container spacing={0} justify="center">
                            <Grid item xs={2}>
                              <Tooltip title={isPhoneInUse(row.id) ? '' : onPhone ? 'End Call' : 'Call Parent'}>
                                <span>
                                  <IconButton
                                    className={classes.iconButton}
                                    color="primary"
                                    disabled={!connected || isPhoneInUse(row.id)}
                                    onClick={() => onClickPhone(row)}>
                                    {isPhoneInUse(row.id) ? (
                                      <PhoneInTalkIcon />
                                    ) : !onPhone ? (
                                      <CallIcon />
                                    ) : (
                                      <CallEndIcon />
                                    )}
                                  </IconButton>
                                </span>
                              </Tooltip>
                            </Grid>

                            <Grid item xs={2}>
                              <Tooltip title="Email Parent">
                                <span>
                                  <IconButton
                                    className={classes.iconButton}
                                    color="primary"
                                    onClick={() => onClickEmail(row)}>
                                    <EmailIcon />
                                  </IconButton>
                                </span>
                              </Tooltip>
                            </Grid>

                            <Grid item xs={2}>
                              <Tooltip title="SMS Management">
                                <span>
                                  <IconButton
                                    className={classes.iconButton}
                                    color="primary"
                                    onClick={() => onClickSMS(row)}>
                                    <Badge badgeContent={getBadgeContent(row.id)} color="secondary">
                                      <SmsIcon />
                                    </Badge>
                                  </IconButton>
                                </span>
                              </Tooltip>
                            </Grid>

                            <Grid item xs={2}>
                              <Tooltip title="Add Manual Logs">
                                <span>
                                  <IconButton
                                    className={classes.iconButton}
                                    color="primary"
                                    onClick={() => onClickManualLogs(row)}>
                                    <AddCircleIcon />
                                  </IconButton>
                                </span>
                              </Tooltip>
                            </Grid>

                            <Grid item xs={2}>
                              <Tooltip title="User Management">
                                <span>
                                  <IconButton
                                    className={classes.iconButton}
                                    color="primary"
                                    onClick={() => onClickFollowup(row)}>
                                    <Badge badgeContent={getAccountWarning(row)} color="error">
                                      <AccountCircleIcon />
                                    </Badge>
                                  </IconButton>
                                </span>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>

                      <TableRow key={`${row.id}_module`}>
                        <ModuleProgressCell open={moduleOpen} row={row} />
                      </TableRow>

                      <TableRow key={`${row.id}_logs`}>
                        <UserLogsCell
                          open={logOpen}
                          row={row}
                          logs={getRowLogs(row.id)}
                          loading={logLoading}
                          handleNoteSubmit={handleNoteSubmit}
                          deleteManualLog={deleteManualLog}
                          saveManualLog={saveManualLog}
                          manualLog={manualLogs.indexOf(row.id) >= 0 ? true : false}
                        />
                      </TableRow>

                      <TableRow key={`${row.id}_profile`}>
                        <UserProfileCell open={profileOpen} row={row} />
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow key="empty" style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={
            rows.filter(row => {
              return filterRow(row);
            }).length
          }
          rowsPerPage={rowsPerPage}
          page={page}
          classes={{
            toolbar: classes.toolbar,
            caption: classes.caption,
            menuItem: classes.menuItem,
          }}
          style={{ fontSize: '14px' }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>

      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label={<Typography style={{ fontSize: '14px' }}>Dense Padding</Typography>}
      />

      <Snackbar open={alertOpen} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} variant="filled" className={classes.alert}>
          {alert}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default UserTable;
