import * as React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { UserStatusChipProps } from './types';
import { colors } from '../../styles/variables';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles(() =>
  createStyles({
    chip: {
      color: 'white',
      fontSize: '10px',
    },
    chipActive: {
      backgroundColor: colors.brand,
    },
    chip7: {
      backgroundColor: '#ffba08',
    },
    chip14: {
      backgroundColor: '#e85d04',
    },
    chipInactive: {
      backgroundColor: '#d00000',
    },
  }),
);

function UserStatusChip(props: UserStatusChipProps) {
  const classes = useStyles();

  return (
    <Chip
      className={clsx(classes.chip, {
        [classes.chipActive]: props.status === 'active',
        [classes.chip7]: props.status === '> 7 Days',
        [classes.chip14]: props.status === '> 14 Days',
        [classes.chipInactive]: props.status === 'inactive',
      })}
      size="small"
      label={props.status.toUpperCase()}
    />
  );
}

export default UserStatusChip;
