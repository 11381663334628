import * as React from 'react';
import { useFirestore } from 'react-redux-firebase';
import { colors } from '../../styles/variables';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { VerificationStepperProps } from './types';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      color: 'white',
      fontSize: '12px',
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    backButton: {
      color: colors.gray.calm,
      fontSize: '12px',
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginTop: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
    input: {
      fontSize: '14px',
    },
    label: {
      fontSize: '16px',
    },
    paragraph: {
      fontSize: '16px',
      color: colors.gray.calm,
      paddingLeft: '12px',
    },
    resetParagrah: {
      fontSize: '18px',
      color: colors.gray.dark,
    },
    step: {
      '& $text': {
        fill: 'white',
      },
    },
    menuItem: {
      fontSize: '14px',
    },
  }),
);

function getSteps() {
  return ['Membership Reqeust Details', 'Verification Result'];
}

interface PSGProps {
  name: string;
  id: string;
}

function VerificationStepper(props: VerificationStepperProps) {
  const classes = useStyles();
  const firestore = useFirestore();

  const [activeStep, setActiveStep] = React.useState(0);
  const [email, setEmail] = React.useState('');
  const [code, setCode] = React.useState('');
  const [name, setName] = React.useState('');
  const [showError, setShowError] = React.useState(false);
  const [result, setResult] = React.useState('');
  const [psg, setPsg] = React.useState<PSGProps[]>([]);
  const [selected, setSelected] = React.useState('');
  const steps = getSteps();

  // Load the available PSGs for membership verification
  React.useEffect(() => {
    async function getFacebookGroups() {
      const groups = await firestore.collection('groups').get();

      const psgs: PSGProps[] = [];
      groups?.docs.forEach(group => {
        psgs.push({ name: group.data().name, id: group.id });
      });
      setPsg(psgs);
      setSelected(psgs[0].id);
    }

    getFacebookGroups();
  }, []);

  const activeStepAction = () => {
    return (
      <Grid item xs={12} container justify="flex-end" alignItems="center">
        <Button disabled={activeStep === 0} onClick={handleBack} className={classes.backButton}>
          Back
        </Button>

        {props.verifyLoading ? (
          <CircularProgress size={28} />
        ) : (
          <Button variant="contained" color="primary" onClick={handleNext} className={classes.button}>
            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
          </Button>
        )}
      </Grid>
    );
  };

  // Handle the next button click for the verification stepper
  const handleNext = async () => {
    // Verify the user inputs and show error if one of the field is empty
    if (activeStep === 0) {
      if (!email || !code || !name) setShowError(true);
      else {
        const recordResult = await props.recordPSGMembership(email, code, name, selected);
        setResult(recordResult);
        setActiveStep(prevActiveStep => prevActiveStep + 1);
      }
    } else setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelected(event.target.value as string);
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical" style={{ color: 'white' }}>
        <Step key="request-data">
          <StepLabel
            StepIconProps={{
              classes: { root: classes.step },
            }}>
            Membership Reqeust Details
          </StepLabel>
          <StepContent>
            <div className={classes.actionsContainer}>
              <Grid container spacing={2} justify="center">
                <Grid item xs={10}>
                  <TextField
                    error={email.length === 0 && showError}
                    autoFocus
                    variant="outlined"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setEmail(event.target.value);
                      setShowError(false);
                    }}
                    margin="dense"
                    id="email"
                    label="PiP+ Email"
                    fullWidth
                    value={email}
                    InputLabelProps={{
                      classes: {
                        root: classes.label,
                      },
                    }}
                    InputProps={{
                      classes: {
                        input: classes.input,
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={10}>
                  <TextField
                    error={code.length === 0 && showError}
                    autoFocus
                    variant="outlined"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setCode(event.target.value);
                      setShowError(false);
                    }}
                    margin="dense"
                    id="code"
                    label="Verification Code"
                    fullWidth
                    value={code}
                    InputLabelProps={{
                      classes: {
                        root: classes.label,
                      },
                    }}
                    InputProps={{
                      classes: {
                        input: classes.input,
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={10}>
                  <TextField
                    error={name.length === 0 && showError}
                    autoFocus
                    variant="outlined"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setName(event.target.value);
                      setShowError(false);
                    }}
                    margin="dense"
                    id="name"
                    label="Facebook Name"
                    fullWidth
                    value={name}
                    InputLabelProps={{
                      classes: {
                        root: classes.label,
                      },
                    }}
                    InputProps={{
                      classes: {
                        input: classes.input,
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={10}>
                  <FormControl fullWidth>
                    <Select
                      variant="outlined"
                      labelId="select-psg-label"
                      id="select-psg"
                      value={selected}
                      onChange={handleSelectChange}
                      className={classes.input}>
                      {psg &&
                        psg.map((group, idx) => (
                          <MenuItem className={classes.menuItem} key={idx} value={group.id}>
                            {group.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                {activeStepAction()}
              </Grid>
            </div>
          </StepContent>
        </Step>

        <Step key="verification-result">
          <StepLabel
            StepIconProps={{
              classes: { root: classes.step },
            }}>
            Verification Result
          </StepLabel>

          <StepContent>
            <div className={classes.actionsContainer}>
              <Grid container spacing={2} justify="center">
                <Typography className={classes.paragraph}>{result}</Typography>
                {activeStepAction()}
              </Grid>
            </div>
          </StepContent>
        </Step>
      </Stepper>

      {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography className={classes.resetParagrah}>
            Verification completed - reset to verify more requests
          </Typography>
          <Button variant="contained" color="primary" className={classes.button} onClick={handleReset}>
            Reset
          </Button>
        </Paper>
      )}
    </div>
  );
}

export default VerificationStepper;
