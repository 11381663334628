import React from 'react';
import { colors } from '../../../styles/variables';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    messageRow: {
      display: 'flex',
    },
    messageRowRight: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    messageLeft: {
      position: 'relative',
      marginLeft: '20px',
      marginBottom: '10px',
      padding: '10px',
      backgroundColor: colors.secondary,
      width: '100%',
      minWidth: '100px',
      textAlign: 'left',
      fontSize: '.7em',
      color: 'white',
      fontWeight: 400,
      border: `1px solid ${colors.secondary}`,
      borderRadius: '10px',
      '&:after': {
        content: "''",
        position: 'absolute',
        width: '0',
        height: '0',
        borderTop: `15px solid ${colors.secondary}`,
        borderLeft: '15px solid transparent',
        borderRight: '15px solid transparent',
        top: '0',
        left: '-15px',
      },
      '&:before': {
        content: "''",
        position: 'absolute',
        width: '0',
        height: '0',
        borderTop: `17px solid ${colors.secondary}`,
        borderLeft: '16px solid transparent',
        borderRight: '16px solid transparent',
        top: '-1px',
        left: '-17px',
      },
    },
    messageRight: {
      position: 'relative',
      marginRight: '20px',
      marginBottom: '10px',
      padding: '10px',
      backgroundColor: colors.brand,
      width: '60%',
      textAlign: 'left',
      fontWeight: 400,
      fontSize: '.7em',
      color: 'white',
      border: `1px solid ${colors.brand}`,
      borderRadius: '10px',
      '&:after': {
        content: "''",
        position: 'absolute',
        width: '0',
        height: '0',
        borderTop: `15px solid ${colors.brand}`,
        borderLeft: '15px solid transparent',
        borderRight: '15px solid transparent',
        top: '0',
        right: '-15px',
      },
      '&:before': {
        content: "''",
        position: 'absolute',
        width: '0',
        height: '0',
        borderTop: `17px solid ${colors.brand}`,
        borderLeft: '16px solid transparent',
        borderRight: '16px solid transparent',
        top: '-1px',
        right: '-17px',
      },
    },
    messageContent: {
      padding: 0,
      margin: 0,
      marginBottom: '12px',
    },
    messageTimeStampRight: {
      position: 'absolute',
      fontSize: '.85em',
      fontWeight: 400,
      marginTop: '10px',
      bottom: '3px',
      right: '8px',
    },
    avatar: {
      color: 'white',
      backgroundColor: colors.secondary,
      width: theme.spacing(5),
      height: theme.spacing(5),
      marginTop: '5px',
      marginLeft: '5px',
    },
    displayName: {
      fontSize: '.85em',
      marginLeft: '14px',
      marginTop: '4px',
    },
  }),
);

export const MessageLeft = (props: any) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.messageRow}>
        <Avatar alt={props.displayName} className={classes.avatar}></Avatar>
        <div>
          <div className={classes.displayName}>{props.displayName}</div>
          <div className={classes.messageLeft}>
            <div>
              <p className={classes.messageContent}>{props.message}</p>
            </div>
            <div className={classes.messageTimeStampRight}>{props.timestamp}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export const MessageRight = (props: any) => {
  const classes = useStyles();
  return (
    <div className={classes.messageRowRight}>
      <div className={classes.messageRight}>
        <p className={classes.messageContent}>{props.message}</p>
        <div className={classes.messageTimeStampRight}>{props.timestamp}</div>
      </div>
    </div>
  );
};
