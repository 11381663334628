import * as React from 'react';
import { UserProfileCellProps } from '../types';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TableCell, Collapse, Box, TextField, Grid, GridSize } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      paddingRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
      '& .MuiInputBase-root.Mui-disabled': {
        fontSize: '14px',
        color: 'rgba(0, 0, 0, 0.9)',
      },
      '& .MuiInputLabel-root': {
        fontSize: '16px',
        color: 'rgba(0, 0, 0, 0.6)',
      },
    },
  }),
);

function ProfileData(props: { label: string; value: any; width: GridSize; multiline?: boolean }) {
  const classes = useStyles();
  const { label, value, width, multiline } = props;

  return (
    <Grid item xs={width}>
      <TextField
        fullWidth
        multiline={multiline && true}
        rows={multiline ? 2 : 1}
        className={classes.textField}
        label={label}
        id={label}
        value={value}
        disabled
      />
    </Grid>
  );
}

function UserProfileCell(props: UserProfileCellProps) {
  const profile = props.row.profile;

  return (
    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
      <Collapse in={props.open.indexOf(props.row.id) >= 0} timeout="auto" unmountOnExit>
        <Box my={2} px={8}>
          <Grid container justify="center">
            <ProfileData label="First name" value={profile.userFirstname} width={2} />
            <ProfileData label="Surname" value={profile.userSurname} width={2} />
            <ProfileData label="Age" value={profile.userAge} width={1} />
            <ProfileData label="Gender" value={profile.userGender} width={1} />
            <ProfileData label="Postcode" value={profile.userPostcode} width={1} />
            <ProfileData label="Phone" value={profile.userPhone} width={1} />
            <ProfileData label="Phone alt." value={profile.userPhoneAlt || 'N/A'} width={1} />
            <ProfileData label="Current stage" value={profile.currentStage || 'N/A'} width={1} />
          </Grid>
        </Box>

        <Box my={2} px={8}>
          <Grid container justify="center">
            <ProfileData label="Ethnicity group" value={profile.familyEthnicity || 'N/A'} width={3} />
            <ProfileData label="Relationship status" value={profile.familyRelationship || 'N/A'} width={3} />
            <ProfileData label="Education" value={profile.userEducation || 'N/A'} width={4} />
            <ProfileData label="Income" multiline value={profile.familyIncome || 'N/A'} width={5} />
            <ProfileData label="COVID impact" multiline value={profile.familyCovidImpact || 'N/A'} width={5} />
          </Grid>
        </Box>

        <Box my={2} px={8}>
          <Grid container justify="center">
            <ProfileData label="Your teen's first name" value={profile.childName} width={2} />
            <ProfileData label="Date of birth" value={profile.childDob} width={1} />
            <ProfileData label="School grade" value={profile.childGrade} width={1} />
            <ProfileData label="Gender" value={profile.childGender} width={1} />
            <ProfileData label="Your relationship to them" value={profile.childRelationship} width={2} />
            <ProfileData label="Another parent in program?" value={profile.childOtherParentsJoined} width={2} />
            <ProfileData label="Future research" value={profile.discoverFutureResearch || 'N/A'} width={1} />
          </Grid>
        </Box>

        <Box my={2} px={8}>
          <Grid container justify="center">
            <ProfileData label="How you found us" multiline value={profile.discoverChannels || 'N/A'} width={4} />
            <ProfileData
              label="Reason for joining the program"
              multiline
              value={profile.discoverReason || 'N/A'}
              width={6}
            />
          </Grid>
        </Box>
      </Collapse>
    </TableCell>
  );
}

export default UserProfileCell;
