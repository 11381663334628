import * as React from 'react';
import Page from '../components/Page';
import Container from '../components/Container';
import IndexLayout from '../layouts';
import { PrivateRoute } from '../components/private';
import { UserTable } from '../components/userTable';

const UserDashboard = () => (
  <IndexLayout>
    <PrivateRoute>
      <Page>
        <Container>
          <UserTable />
        </Container>
      </Page>
    </PrivateRoute>
  </IndexLayout>
);

export default UserDashboard;
