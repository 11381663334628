import * as React from 'react';
import clsx from 'clsx';
import { findIndex } from 'lodash';
import { colors } from '../../../styles/variables';
import { UserLogsCellProps, FollowupMessages, FollowupNotesMap } from '../types';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { formattedDateTime } from '../../../utils/timeFormatter';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Collapse from '@material-ui/core/Collapse';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import EditIcon from '@material-ui/icons/Edit';
import CallIcon from '@material-ui/icons/Call';
import EmailIcon from '@material-ui/icons/Email';
import SmsIcon from '@material-ui/icons/Sms';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableCell: {
      fontSize: '12px',
      paddingLeft: 0,
      paddingRight: '32px',
      paddingTop: '4px',
      paddingBottom: '4px',
    },
    chip: {
      color: 'white',
      fontSize: '12px',
    },
    chipDone: {
      backgroundColor: colors.brand,
    },
    chipRunning: {
      backgroundColor: colors.secondary,
      animation: `$highlight 3s infinite`,
    },
    '@keyframes highlight': {
      '0%': {
        backgroundColor: colors.warning,
      },
      '50%': {
        backgroundColor: '#E9EAE0',
      },
      '100%': {
        backgroundColor: colors.warning,
      },
    },
    chipEnded: {
      backgroundColor: colors.secondary,
    },
    input: {
      fontSize: '12px',
    },
    label: {
      fontSize: '16px',
    },
    button: {
      marginLeft: theme.spacing(1),
      '&:disabled': {
        color: colors.gray.disabled,
        backgroundColor: 'transparent',
        cursor: 'not-allowed',
        pointerEvents: 'all !important',
      },
    },
  }),
);

const headCells = [
  { id: 'type', label: 'Type' },
  { id: 'startTime', label: 'Start Time' },
  { id: 'endTime', label: 'End Time' },
  { id: 'admin', label: 'Admin' },
  { id: 'status', label: 'Status' },
  { id: 'notes', label: 'Notes' },
];

function UserLogsCell(props: UserLogsCellProps) {
  const classes = useStyles();
  const [notes, setNotes] = React.useState<FollowupNotesMap[] | null>(null);
  const [editRow, setEditRow] = React.useState<number[]>([]);
  const [manualLog, setManualLog] = React.useState<string>('');

  const onChange = (event: React.ChangeEvent<HTMLInputElement>, log: FollowupMessages) => {
    const id = log.startTime.seconds;
    const note = event.target.value;
    if (!notes) setNotes([{ startTime: id, note: note }]);
    else {
      const idx = findIndex(notes, note => {
        return note.startTime === id;
      });
      const tmpNotes = [...notes];
      if (idx >= 0) tmpNotes[idx].note = note;
      else tmpNotes.push({ startTime: id, note: note });
      setNotes(tmpNotes);
    }
  };

  const changeFollowupNote = (log: FollowupMessages) => {
    const id = log.startTime.seconds;
    const note = log.notes;
    if (!notes) setNotes([{ startTime: id, note: note }]);
    else {
      const idx = findIndex(notes, note => {
        return note.startTime === id;
      });
      const tmpNotes = [...notes];
      if (idx >= 0) tmpNotes[idx].note = note;
      else tmpNotes.push({ startTime: id, note: note });
      setNotes(tmpNotes);
    }
  };

  const setFollowupNote = (log: FollowupMessages) => {
    const id = log.startTime.seconds;
    if (!notes) return;
    else {
      const idx = findIndex(notes, note => {
        return note.startTime === id;
      });
      if (idx >= 0) {
        const tmpNotes = [...notes];
        tmpNotes.splice(idx, 1);
        setNotes(tmpNotes);
      }
      return;
    }
  };

  const getFollowupNote = (log: FollowupMessages) => {
    const id = log.startTime.seconds;
    if (!notes) return '';
    else {
      const idx = findIndex(notes, note => {
        return note.startTime === id;
      });
      if (idx >= 0) return notes[idx].note;
      return '';
    }
  };

  const updateEditRows = (startSecond: number) => {
    const idx = editRow.indexOf(startSecond);
    const tmpRow = [...editRow];
    if (idx >= 0) tmpRow.splice(idx, 1);
    else tmpRow.push(startSecond);
    setEditRow(tmpRow);
  };

  // Render the log icon based on the given log type
  const renderLogTypeIcon = (type: string) => {
    switch (type) {
      case 'Call':
        return <CallIcon color="primary" />;
      case 'Email':
        return <EmailIcon color="primary" />;
      case 'SMS':
        return <SmsIcon color="primary" />;
      case 'Manual':
        return <SupervisorAccountIcon color="primary" />;
      default:
        return <SupervisorAccountIcon color="primary" />;
    }
  };

  return (
    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
      <Collapse in={props.open.indexOf(props.row.id) >= 0} timeout="auto" unmountOnExit>
        <Box marginTop={2} marginBottom={6} paddingLeft={12} paddingRight={12}>
          <Table size="small" aria-label="purchases">
            <TableHead>
              <TableRow>
                {headCells.map(headCell => (
                  <TableCell
                    key={headCell.id}
                    align="left"
                    padding="none"
                    style={{ paddingRight: '8px', fontSize: '14px', fontWeight: 'bold' }}>
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {props.manualLog && (
                <TableRow key="manual-log">
                  <TableCell className={classes.tableCell}>
                    <NewReleasesIcon color="primary" />
                  </TableCell>
                  <TableCell className={classes.tableCell}> </TableCell>
                  <TableCell className={classes.tableCell}> </TableCell>
                  <TableCell className={classes.tableCell}> </TableCell>
                  <TableCell className={classes.tableCell}> </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Box minWidth="400px" display="flex" alignItems="center">
                      <Box width="90%">
                        <TextField
                          autoFocus
                          multiline
                          variant="outlined"
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setManualLog(event.target.value);
                          }}
                          margin="dense"
                          id="name"
                          fullWidth
                          label="Manual Logs"
                          value={manualLog}
                          InputLabelProps={{
                            classes: {
                              root: classes.label,
                            },
                          }}
                          InputProps={{
                            classes: {
                              input: classes.input,
                            },
                          }}
                        />
                      </Box>

                      <Box>
                        <Tooltip title="Save Log">
                          <span>
                            <IconButton
                              disabled={props.loading}
                              className={classes.button}
                              onClick={() => {
                                props.saveManualLog(props.row.id, manualLog);
                                setManualLog('');
                              }}>
                              {props.loading ? <CircularProgress size={32} /> : <DoneAllIcon color="primary" />}
                            </IconButton>
                          </span>
                        </Tooltip>
                      </Box>

                      <Box>
                        <Tooltip title="Cancel">
                          <span>
                            <IconButton
                              disabled={props.loading}
                              className={classes.button}
                              onClick={() => {
                                props.deleteManualLog(props.row.id, props.logs);
                                setManualLog('');
                              }}>
                              <DeleteForeverIcon color="primary" />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              )}

              {props.logs &&
                props.logs.map((log, idx) => (
                  <TableRow key={idx}>
                    <TableCell className={classes.tableCell}>{renderLogTypeIcon(log.type)}</TableCell>
                    <TableCell className={classes.tableCell}>
                      {formattedDateTime(log.startTime.seconds * 1000)}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {log.endTime ? formattedDateTime(log.endTime.seconds * 1000) : ''}
                    </TableCell>
                    <TableCell className={classes.tableCell}>{log.admin}</TableCell>
                    <TableCell className={classes.tableCell}>
                      <Chip
                        className={clsx(classes.chip, {
                          [classes.chipDone]: log.status === 'done' || log.status === 'sent',
                          [classes.chipRunning]: log.status === 'running',
                          [classes.chipEnded]: log.status === 'ended',
                        })}
                        size="small"
                        label={log.status.toUpperCase()}
                      />
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Box minWidth="400px" display="flex" alignItems="center">
                        <Box width="90%">
                          {(log.status === 'done' && editRow.indexOf(log.startTime.seconds) < 0) ||
                          log.status === 'sent' ? (
                            log.notes
                          ) : (
                            <TextField
                              autoFocus
                              multiline
                              disabled={log.status === 'done' && editRow.indexOf(log.startTime.seconds) < 0}
                              variant="outlined"
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event, log)}
                              margin="dense"
                              id="name"
                              fullWidth
                              label="Followup Notes"
                              value={
                                log.status === 'done' && editRow.indexOf(log.startTime.seconds) < 0
                                  ? log.notes
                                  : getFollowupNote(log)
                              }
                              InputLabelProps={{
                                classes: {
                                  root: classes.label,
                                },
                              }}
                              InputProps={{
                                classes: {
                                  input: classes.input,
                                },
                              }}
                            />
                          )}
                        </Box>

                        {log.status !== 'sent' && (
                          <Box display="flex" alignItems="center">
                            {log.status !== 'done' || editRow.indexOf(log.startTime.seconds) >= 0 ? (
                              <React.Fragment>
                                <Tooltip title={log.status === 'running' ? '' : 'Save Log'}>
                                  <span>
                                    <IconButton
                                      disabled={props.loading || log.status === 'running'}
                                      color="primary"
                                      className={classes.button}
                                      onClick={() => {
                                        props.handleNoteSubmit(getFollowupNote(log), props.row.id, log.startTime);
                                        setFollowupNote(log);
                                        const startSeconds = log.startTime.seconds;
                                        if (editRow.indexOf(startSeconds) >= 0) updateEditRows(startSeconds);
                                      }}>
                                      {props.loading ? <CircularProgress size={32} /> : <DoneAllIcon />}
                                    </IconButton>
                                  </span>
                                </Tooltip>
                                <Tooltip title={log.status === 'running' ? '' : 'Cancel'}>
                                  <span>
                                    <IconButton
                                      disabled={props.loading || log.status === 'running'}
                                      color="primary"
                                      className={classes.button}
                                      onClick={() => {
                                        updateEditRows(log.startTime.seconds);
                                      }}>
                                      <DeleteForeverIcon />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              </React.Fragment>
                            ) : (
                              <Tooltip title="Edit Log">
                                <span>
                                  <IconButton
                                    disabled={props.loading}
                                    className={classes.button}
                                    onClick={() => {
                                      updateEditRows(log.startTime.seconds);
                                      changeFollowupNote(log);
                                    }}>
                                    <EditIcon color="primary" />
                                  </IconButton>
                                </span>
                              </Tooltip>
                            )}
                          </Box>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </Collapse>
    </TableCell>
  );
}

export default UserLogsCell;
