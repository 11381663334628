import * as React from 'react';
import { ModuleProgressCellProps } from '../types';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { merge, map } from 'lodash';
import ReactApexChart from 'react-apexcharts';
import { BaseOptionChart } from '../../charts';
import { formattedDateTime } from '../../../utils/timeFormatter';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import Collapse from '@material-ui/core/Collapse';

const useStyles = makeStyles(() =>
  createStyles({
    chartTitle: {
      fontSize: '20px',
      fontWeight: 'bold',
    },
  }),
);

function ModuleProgressCell(props: ModuleProgressCellProps) {
  const classes = useStyles();

  const getChartLabels = (labels: string[]) => {
    const tmpLabels: string[] = [];
    labels.forEach(item => {
      tmpLabels.push(item.replace('m', 'Module '));
    });
    return tmpLabels;
  };

  const chartOptions: any = merge(BaseOptionChart(), {
    tooltip: {
      marker: { show: false },
      y: {
        formatter: function(value: any, { dataPointIndex }: any) {
          const completedAt = props.row.moduleCompletion[dataPointIndex].completedAt;
          return `${Math.round(value * 100)}%${
            completedAt ? ' - Completed on ' + formattedDateTime(completedAt.seconds * 1000) : ''
          }`;
        },
        title: {
          formatter: () => `# Module Completion: `,
        },
      },
    },
    plotOptions: {
      bar: { horizontal: true, barHeight: '40%', borderRadius: 2 },
    },
    xaxis: {
      categories: props.row.moduleCompletion ? getChartLabels(map(props.row.moduleCompletion, 'label')) : [],
    },
  });

  return (
    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
      <Collapse in={props.open.indexOf(props.row.id) >= 0} timeout="auto" unmountOnExit>
        <Box marginTop={2} paddingLeft={36} paddingRight={36}>
          <Typography className={classes.chartTitle} variant="h6">
            Module Completion
          </Typography>
          {props.row.moduleCompletion && (
            <ReactApexChart
              type="bar"
              series={[{ data: map(props.row.moduleCompletion, 'value') }]}
              options={chartOptions}
              height={235}
            />
          )}
        </Box>
      </Collapse>
    </TableCell>
  );
}

export default ModuleProgressCell;
