import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { SMSDialogProps } from '../types';
import { MessageLeft, MessageRight } from './Message';
import { formattedDateTime } from '../../../utils/timeFormatter';
import { colors } from '../../../styles/variables';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import SendIcon from '@material-ui/icons/Send';
import DoneAllIcon from '@material-ui/icons/DoneAll';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginLeft: theme.spacing(1),
      '&:disabled': {
        color: colors.gray.disabled,
        backgroundColor: 'transparent',
        cursor: 'not-allowed',
        pointerEvents: 'all !important',
      },
    },
    dialogContent: {
      fontSize: '18px',
      marginBottom: theme.spacing(4),
    },
    input: {
      fontSize: '16px',
    },
    label: {
      fontSize: '18px',
    },
    menuItem: {
      fontSize: '16px',
    },
    paper: {
      width: '100%',
      height: '80vh',
      maxHeight: '450px',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      position: 'relative',
      border: 0,
    },
    messagesBody: {
      width: 'calc( 100% - 20px )',
      margin: 10,
      overflowY: 'scroll',
      height: 'calc( 100% - 80px )',
    },
  }),
);

function SMSDialog(props: SMSDialogProps) {
  const classes = useStyles();
  const [smsContent, setSmsContent] = React.useState('');
  const [isReply, setIsReply] = React.useState(true);
  const [refVisible, setRefVisible] = React.useState(false);
  const messagesRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSmsContent(event.target.value);
  };

  React.useEffect(() => {
    if (!refVisible) return;
    messagesRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [refVisible]);

  return (
    <div>
      <Dialog open={props.open} onClose={props.handleSmsClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Manage SMS</DialogTitle>
        <DialogContent>
          <DialogContentText
            className={
              classes.dialogContent
            }>{`Find the text message conversation with ${props.row?.name} here. Follow up on the requests if necessary!`}</DialogContentText>

          <Paper className={classes.paper}>
            <Paper id="message-paper" className={classes.messagesBody}>
              {props.messages &&
                props.messages.map((m, idx) =>
                  m.from === 'parent' ? (
                    <MessageLeft
                      key={idx}
                      message={m.message}
                      timestamp={formattedDateTime(m.createdAt.seconds * 1000)}
                      displayName={props.row ? props.row.name : 'Parent'}
                      avatarDisp={true}
                    />
                  ) : (
                    <MessageRight
                      key={idx}
                      message={m.message}
                      timestamp={formattedDateTime(m.createdAt.seconds * 1000)}
                      avatarDisp={true}
                    />
                  ),
                )}
              <div
                ref={(element: HTMLDivElement) => {
                  messagesRef.current = element;
                  setRefVisible(!!element);
                }}
              />
            </Paper>

            <Box width="95%" display="flex">
              <Box width="90%">
                <TextField
                  autoFocus
                  multiline
                  variant="outlined"
                  onChange={onChange}
                  margin="dense"
                  id="name"
                  fullWidth
                  label="SMS Message"
                  value={smsContent}
                  InputLabelProps={{
                    classes: {
                      root: classes.label,
                    },
                  }}
                  InputProps={{
                    classes: {
                      input: classes.input,
                    },
                  }}
                />
              </Box>

              <Box>
                <Tooltip title={!props.row?.smsNotification ? '' : 'Send Message'}>
                  <span>
                    <IconButton
                      disabled={props.smsLoading}
                      color="primary"
                      onClick={() => {
                        setIsReply(true);
                        props.handleSmsSend(smsContent);
                        setSmsContent('');
                      }}
                      className={classes.button}>
                      {props.smsLoading && isReply ? <CircularProgress size={32} /> : <SendIcon />}
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>

              <Box>
                <Tooltip title={props.smsLoading || props.needResolve === 0 ? '' : 'Mark as Resolved'}>
                  <span>
                    <IconButton
                      disabled={props.smsLoading || props.needResolve === 0}
                      color="primary"
                      onClick={() => {
                        setIsReply(false);
                        props.handleSmsResolved();
                      }}
                      className={classes.button}>
                      {props.smsLoading && !isReply ? <CircularProgress size={32} /> : <DoneAllIcon />}
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
            </Box>
          </Paper>
        </DialogContent>

        <DialogActions>
          <Box width="100%"></Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SMSDialog;
