import * as React from 'react';
import { VerifyDialogProps } from '../types';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import VerificationStepper from '../VerificationStepper';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper, { PaperProps } from '@material-ui/core/Paper';
import Draggable from 'react-draggable';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: 'white',
      fontSize: '14px',
      margin: theme.spacing(1),
    },
    dialogContent: {
      fontSize: '18px',
      marginBottom: theme.spacing(2),
    },
  }),
);

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

function VerifyDialog(props: VerifyDialogProps) {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleVerifyClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title">
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Verify PSG Membership
        </DialogTitle>

        <DialogContent>
          <DialogContentText className={classes.dialogContent}>
            Please take the following steps to verify the PSG membership request and record the user PSG joining
            information:
          </DialogContentText>

          <VerificationStepper
            verifyPSGToken={props.verifyPSGToken}
            recordPSGMembership={props.recordPSGMembership}
            verifyLoading={props.verifyLoading}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default VerifyDialog;
