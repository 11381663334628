import React from 'react';
import { Checkbox as MuiCheckbox, Typography } from '@material-ui/core';
import { FormControlLabel } from '@material-ui/core';

export type CheckboxProps = {
  id: string;
  label: string;
  value: any;
  disabled: boolean;
  onChange: (value: boolean) => void;
};

export function Checkbox(props: CheckboxProps) {
  const { label, value, disabled, onChange } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    onChange(checked);
  };

  return (
    <FormControlLabel
      disabled={disabled}
      control={<MuiCheckbox checked={value && value === true} onChange={handleChange} name={label} />}
      label={<Typography variant="body2">{label}</Typography>}
    />
  );
}
