import * as React from 'react';
import { UserDialogProps } from '../types';
import { colors } from '../../../styles/variables';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper, { PaperProps } from '@material-ui/core/Paper';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import UpdateIcon from '@material-ui/icons/Update';
import Draggable from 'react-draggable';
import { Checkbox } from '../Checkbox';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: 'white',
      fontSize: '14px',
      marginTop: theme.spacing(2),
    },
    dialogContent: {
      fontSize: '16px',
      fontStyle: 'italic',
      color: colors.gray.calm,
      marginBottom: theme.spacing(1),
    },
    input: {
      fontSize: '16px',
    },
    label: {
      fontSize: '18px',
    },
    icon: {
      fontSize: '18px',
      marginLeft: theme.spacing(1),
    },
    buttonProgress: {
      marginLeft: theme.spacing(1),
    },
    subtitle: {
      fontSize: '16px',
      fontWeight: 600,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    divider: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    alert: {
      fontSize: '14px',
      marginBottom: '8px',
    },
  }),
);

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

function UserDialog(props: UserDialogProps) {
  const classes = useStyles();

  const [state, setState] = React.useState({
    email: '',
    phone: '',
    followupNote: '',
    optOutNote: '',
    contactNote: '',
  });
  const [emailLock, setEmailLock] = React.useState(true);
  const [phoneLock, setPhoneLock] = React.useState(true);
  const [smsNotification, setSmsNotification] = React.useState(true);

  React.useEffect(() => {
    if (props.row) {
      setState({
        ...state,
        email: props.row.email,
        phone: props.row.phone,
      });
      setSmsNotification(props.row.smsNotification);
    }
  }, [props.row]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const resetState = () => {
    setState({
      email: '',
      phone: '',
      followupNote: '',
      optOutNote: '',
      contactNote: '',
    });
    setEmailLock(true);
    setPhoneLock(true);
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={() => {
          resetState();
          props.handleFollowupClose();
        }}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title">
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          User Management
        </DialogTitle>

        <DialogContent>
          <Grid container direction="row">
            <Grid item xs={12}>
              <Typography className={classes.subtitle}>Update Email</Typography>
            </Grid>

            <Grid item xs={12} container spacing={1} alignItems="center">
              {props.row?.emailFlag && (
                <Grid item xs={10}>
                  <Alert severity="warning" color="warning" className={classes.alert}>
                    {`Email cannot be delivered: ${props.row?.emailFlag}`}
                  </Alert>
                </Grid>
              )}

              <Grid item xs={10}>
                <TextField
                  autoFocus
                  variant="outlined"
                  onChange={onChange}
                  disabled={emailLock}
                  name="email"
                  fullWidth
                  value={state.email}
                  InputLabelProps={{
                    classes: {
                      root: classes.label,
                    },
                  }}
                  InputProps={{
                    classes: {
                      input: classes.input,
                    },
                  }}
                />
              </Grid>

              <Grid item xs={2}>
                {emailLock ? (
                  <Tooltip title="Unlock">
                    <IconButton
                      className={classes.button}
                      onClick={() => {
                        setEmailLock(false);
                      }}>
                      {props.emailLoading ? (
                        <CircularProgress size={24} className={classes.buttonProgress} />
                      ) : (
                        <LockOpenIcon color="primary" />
                      )}
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Update Email">
                    <IconButton
                      disabled={false}
                      className={classes.button}
                      onClick={() => {
                        setEmailLock(true);
                        props.updateUserDetail('email', state.email);
                      }}>
                      <UpdateIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Typography className={classes.subtitle}>Update Phone</Typography>
            </Grid>

            <Grid item xs={12} container spacing={1} alignItems="center">
              {props.row?.smsFlag && (
                <Grid item xs={10}>
                  <Alert severity="warning" color="warning" className={classes.alert}>
                    {`SMS cannot be delivered: ${props.row?.smsFlag}`}
                  </Alert>
                </Grid>
              )}

              <Grid item xs={10}>
                <TextField
                  autoFocus
                  variant="outlined"
                  onChange={onChange}
                  name="phone"
                  disabled={phoneLock}
                  fullWidth
                  value={state.phone}
                  InputLabelProps={{
                    classes: {
                      root: classes.label,
                    },
                  }}
                  InputProps={{
                    classes: {
                      input: classes.input,
                    },
                  }}
                />
              </Grid>

              <Grid item xs={2}>
                {phoneLock ? (
                  <Tooltip title="Unlock">
                    <IconButton
                      disabled={false}
                      className={classes.button}
                      onClick={() => {
                        setPhoneLock(false);
                      }}>
                      {props.phoneLoading ? (
                        <CircularProgress size={24} className={classes.buttonProgress} />
                      ) : (
                        <LockOpenIcon color="primary" />
                      )}
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Update Phone">
                    <IconButton
                      disabled={false}
                      className={classes.button}
                      onClick={() => {
                        setPhoneLock(true);
                        props.updateUserDetail('phone', state.phone);
                      }}>
                      <UpdateIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>

              <Grid item xs={12}>
                <Typography className={classes.subtitle}>Receive SMS Notifications</Typography>
              </Grid>

              <Grid item xs={12} container spacing={1} alignItems="center">
                <Grid item xs={12}>
                  <Checkbox
                    id="smsNotification"
                    value={smsNotification}
                    label="Receive SMS Notifications"
                    disabled={false}
                    onChange={value => {
                      setSmsNotification(value);
                      props.updateSMSNotification(value);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {props.userStatus.length === 0 && (
            <React.Fragment>
              <Divider className={classes.divider} />

              <Grid item xs={12}>
                <Typography className={classes.subtitle}>Stop User Followup</Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  className={
                    classes.dialogContent
                  }>{`Please record the reason why you would like to stop all follow-up actions for ${props.row?.name}:`}</Typography>
              </Grid>

              <Grid item xs={12} container alignItems="center">
                <TextField
                  autoFocus
                  multiline
                  variant="outlined"
                  onChange={onChange}
                  margin="dense"
                  name="followupNote"
                  label="Stop Followup Reason"
                  fullWidth
                  value={state.followupNote}
                  InputLabelProps={{
                    classes: {
                      root: classes.label,
                    },
                  }}
                  InputProps={{
                    classes: {
                      input: classes.input,
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} container justify="flex-end">
                <Button
                  onClick={() => {
                    props.addStopFollowupUser(state.followupNote);
                    setState({ ...state, followupNote: '' });
                  }}
                  variant="contained"
                  size="small"
                  color="primary"
                  disabled={props.followupLoading}
                  className={classes.button}>
                  Stop Followup
                  {props.followupLoading ? (
                    <CircularProgress size={18} className={classes.buttonProgress} />
                  ) : (
                    <NotInterestedIcon className={classes.icon} />
                  )}
                </Button>
              </Grid>
            </React.Fragment>
          )}

          {props.userStatus !== 'opt-out' && props.userStatus !== 'contact completed' && (
            <React.Fragment>
              <Divider className={classes.divider} />

              <Grid item xs={12}>
                <Typography className={classes.subtitle}>Mark Contact Completed</Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  className={
                    classes.dialogContent
                  }>{`Please record the reason why contact has been completed for ${props.row?.name}:`}</Typography>
              </Grid>

              <Grid item xs={12} container alignItems="center">
                <TextField
                  autoFocus
                  multiline
                  variant="outlined"
                  onChange={onChange}
                  margin="dense"
                  name="contactNote"
                  label="Contact Completed Reason"
                  fullWidth
                  value={state.contactNote}
                  InputLabelProps={{
                    classes: {
                      root: classes.label,
                    },
                  }}
                  InputProps={{
                    classes: {
                      input: classes.input,
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} container justify="flex-end">
                <Button
                  onClick={() => {
                    props.addContactCompletedUser(state.contactNote);
                    setState({ ...state, contactNote: '' });
                  }}
                  variant="contained"
                  size="small"
                  color="primary"
                  disabled={props.followupLoading}
                  className={classes.button}>
                  Contact Completed
                  {props.followupLoading ? (
                    <CircularProgress size={18} className={classes.buttonProgress} />
                  ) : (
                    <NotInterestedIcon className={classes.icon} />
                  )}
                </Button>
              </Grid>
            </React.Fragment>
          )}

          {props.userStatus !== 'opt-out' && (
            <React.Fragment>
              <Divider className={classes.divider} />

              <Grid item xs={12}>
                <Typography className={classes.subtitle}>Opt out User</Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  className={
                    classes.dialogContent
                  }>{`Please record the reason why you would like to opt ${props.row?.name} out:`}</Typography>
              </Grid>

              <Grid item xs={12} container alignItems="center">
                <TextField
                  autoFocus
                  multiline
                  variant="outlined"
                  onChange={onChange}
                  margin="dense"
                  name="optOutNote"
                  label="Opt out Reason"
                  fullWidth
                  value={state.optOutNote}
                  InputLabelProps={{
                    classes: {
                      root: classes.label,
                    },
                  }}
                  InputProps={{
                    classes: {
                      input: classes.input,
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} container justify="flex-end">
                <Button
                  onClick={() => {
                    props.optOutUser(state.optOutNote);
                    setState({ ...state, optOutNote: '' });
                  }}
                  variant="contained"
                  size="small"
                  color="primary"
                  disabled={props.followupLoading}
                  className={classes.button}>
                  Opt out User
                  {props.followupLoading ? (
                    <CircularProgress size={18} className={classes.buttonProgress} />
                  ) : (
                    <NotInterestedIcon className={classes.icon} />
                  )}
                </Button>
              </Grid>
            </React.Fragment>
          )}
        </DialogContent>

        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}

export default UserDialog;
