import * as React from 'react';
import { find } from 'lodash';
import { EmailFollowupDialogProps } from '../types';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import BlockIcon from '@material-ui/icons/Block';
import SendIcon from '@material-ui/icons/Send';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: 'white',
      fontSize: '14px',
      margin: theme.spacing(1),
    },
    dialogContent: {
      fontSize: '18px',
      marginBottom: theme.spacing(4),
    },
    input: {
      fontSize: '16px',
    },
    label: {
      fontSize: '18px',
    },
    menuItem: {
      fontSize: '16px',
    },
    icon: {
      fontSize: '18px',
      marginLeft: theme.spacing(1),
    },
    buttonProgress: {
      marginLeft: theme.spacing(1),
    },
  }),
);

function EmailFollowupDialog(props: EmailFollowupDialogProps) {
  const classes = useStyles();
  const [emailContent, setEmailContent] = React.useState('');
  const [selected, setSelected] = React.useState('None');

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailContent(event.target.value);
  };

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelected(event.target.value as string);
    const template = find(props.templates, template => template.label === event.target.value);
    if (template) setEmailContent(template.content);
    else setEmailContent('');
  };

  return (
    <div>
      <Dialog open={props.open} onClose={props.handleEmailClose} keepMounted aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Send Follow-up Email</DialogTitle>
        <DialogContent>
          <DialogContentText
            className={
              classes.dialogContent
            }>{`You can send a templated follow-up email to ${props.row?.name} with customized PiP+ branding. For common questions, check a list of template responses in the following dropdown list:`}</DialogContentText>

          <Box mb={4} display="flex" justifyContent="center">
            <Box width="80%">
              <FormControl fullWidth>
                <Select
                  labelId="select-label"
                  id="select-template"
                  value={selected}
                  onChange={handleSelectChange}
                  className={classes.input}>
                  <MenuItem className={classes.menuItem} key="None" value="None">
                    None
                  </MenuItem>
                  {props.templates &&
                    props.templates.map((template, index) => (
                      <MenuItem className={classes.menuItem} key={index} value={template.label}>
                        {template.label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          </Box>

          <TextField
            autoFocus
            multiline
            variant="outlined"
            onChange={onChange}
            margin="dense"
            id="name"
            label="Email Content"
            fullWidth
            value={emailContent}
            InputLabelProps={{
              classes: {
                root: classes.label,
              },
            }}
            InputProps={{
              classes: {
                input: classes.input,
              },
            }}
          />
        </DialogContent>

        <DialogActions>
          <Button
            onClick={props.handleEmailClose}
            variant="contained"
            size="small"
            color="secondary"
            className={classes.button}
            startIcon={<BlockIcon />}>
            Cancel
          </Button>

          <Button
            onClick={() => {
              props.handleEmailSend(emailContent);
              setEmailContent('');
            }}
            variant="contained"
            size="small"
            color="primary"
            disabled={props.emailLoading}
            className={classes.button}>
            Send Email
            {props.emailLoading ? (
              <CircularProgress size={18} className={classes.buttonProgress} />
            ) : (
              <SendIcon className={classes.icon} />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EmailFollowupDialog;
