import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Data, HeadCell, Order } from './types';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

const useStyles = makeStyles(() =>
  createStyles({
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }),
);

const headCells: HeadCell[] = [
  { id: 'id', numeric: false, disablePadding: true, label: 'User_ID' },
  { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
  { id: 'flagged', numeric: false, disablePadding: false, label: 'Flagged' },
  { id: 'phone', numeric: false, disablePadding: false, label: 'Phone' },
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'createdAt', numeric: false, disablePadding: false, label: 'Profile_Created_At' },
  { id: 'userType', numeric: false, disablePadding: false, label: 'User_Type' },
  { id: 'pradasComplete', numeric: true, disablePadding: false, label: 'Survey_Progress' },
  { id: 'followupSurveyCompletion', numeric: true, disablePadding: false, label: 'Followup_Progress' },
  { id: 'surveyCompletedAt', numeric: false, disablePadding: false, label: 'Survey_Completed_At' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
];

interface EnhancedTableProps {
  onRequestSort: (property: keyof Data) => void;
  order: Order;
  orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const classes = useStyles();
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler = (property: keyof Data) => () => {
    onRequestSort(property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell style={{ width: '120px' }} />
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? 'none' : 'default'}
            style={{ paddingRight: '8px', fontSize: '14px', fontWeight: 'bold' }}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align="center" style={{ paddingRight: '8px', fontSize: '14px', fontWeight: 'bold' }}>
          Joined_PSGs
        </TableCell>
        <TableCell align="center" style={{ width: '220px', fontSize: '14px', fontWeight: 'bold' }}>
          Follow_Up
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead;
